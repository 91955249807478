<i18n src="@i18n/drone/drone_model.th.yaml" locale="th" lang="yaml"/>
<i18n src="@i18n/drone/drone_lot.th.yaml" locale="th" lang="yaml"/>
<i18n locale="th" lang="yaml" >
warranty_duration.display : '{count} @:common.day'
warranty_duration.display.zero : 'ไม่รับประกัน'
warranty_flight_time.display : '{count} @:common.hour'
warranty_flight_time.display.zero : 'ไม่รับประกัน'
num_drones.display : '{count} @:common.drone.unit'
num_drones.display.zero : '-'
drone_model.field.warranty_flight_time.display : "รับประกัน"
</i18n>

<template>
	<a-card
		:bordered="false"
		:class="cardCssClasses"
		:loading="loading">
		<a-button
			v-if="canUpdate"
			class="mycard-button-action"
			shape="circle"
			icon="edit"
			@click="handleUpdateClick" />
		<div class="mycard-content">
			<div class="mycard-header">
				<ImageLoader
					class="mycard-image"
					:src="model.imageUrl"
					border-type="round">
					<DroneIcon class="anticon" />
				</ImageLoader>
			</div>
			<ul class="mycard-details">
				<li>
					<span class="label">{{$t('drone_lot.field.lot_name')}}</span>
					<span class="value">{{lot.lotName}}</span>
				</li>
				<li>
					<span class="label">{{$t('drone_lot.field.manufacture_date')}}</span>
					<span class="value">{{lot.manufactureDate | date}}</span>
				</li>
				<li v-if="showNumberOfDrones">
					<span class="label">{{$t('drone_lot.field.num_drones')}}</span>
					<span class="value">{{$tc_my('num_drones.display',lot.numDrones)}}</span>
				</li>

				<li>
					<a-card :class="modelCardCssClasses">
						<div class="mycard-content">
							<ul class="mycard-details">
								<li>
									<span class="label">{{$t('drone_model.field.name')}}</span>
									<span class="value">
										<my-router-link name="drone_model/view" :param-value="model.id" auth-action="read" auth-module="droneModel">
											{{model.name}}
										</my-router-link>
									</span>
								</li>
								<li>
									<span class="label">{{$t('drone_model.field.display_name')}}</span>
									<span class="value">
										<my-router-link name="drone_model/view" :param-value="model.id" auth-action="read" auth-module="droneModel">
											{{model.displayName}}
										</my-router-link>
									</span>
								</li>
								<li>
									<span class="label">{{$t('drone_model.field.drone_type')}}</span>
									<span class="value">{{$tenum('drone_type',model.type)}}</span>
								</li>
								<li>
									<span class="label">{{$t('drone_model.field.status')}}</span>
									<span class="value">{{$tenum('drone_model.status',droneModelStatus)}}</span>
								</li>
								<li class="separator"></li>

								<li>
									<span class="label">{{$t('drone_model.field.warranty_duration')}}</span>
									<span class="value">{{$tc_my('warranty_duration.display',model.warrantyDuration)}}</span>
								</li>
								<li>
									<span class="label">{{$t('drone_model.field.warranty_flight_time.display')}}</span>
									<span class="value">{{$tc_my('warranty_flight_time.display',model.warrantyFlightTime)}}</span>
								</li>
							</ul>
						</div>
					</a-card>
				</li>
			</ul>

		</div>
	</a-card>
</template>

<script>
import DroneIcon from "@components/icon/DroneIcon.vue"
import ImageLoader from "@components/common/ImageLoader.vue"
import {getStatusActiveByDeletedAt} from "@utils/commonUtil"
export default {
	components : {
		ImageLoader,DroneIcon
	} ,

	props : {
		loading : {
			type : Boolean,
			default: false
		} ,
		model : {
			type : null,
			default : () => []
		} ,
		lot : {
			type : null,
			default : () => []
		} ,
		showUpdate : {
			type: Boolean,
			default: false
		}
	},

	computed : {
		canUpdate() {
			return this.showUpdate && this.lot.id && this.$can('update', 'droneLot')
		} ,
		modelCardCssClasses() {
			return [
				"drone-model-subcard","mycard", "mycard-with-details",
				{
					"mycard-inactive" : this.model.status === 'inactive'
				}
			]
		} ,
		cardCssClasses() {
			return [
				"drone-lot-card","mycard", "mycard-with-details"
			]
		} ,
		showNumberOfDrones() {
			return this.$authorize('listAll','drone')
		},
		droneModelStatus(){
			return getStatusActiveByDeletedAt(this.model?.deletedAt)
		},
	} ,
	methods : {
		handleUpdateClick() {
			this.$open({name:'drone_lot/update',params : {id : this.lot.id}})
		}
	}
}
</script>
<style lang="less" scoped>
.drone-model-subcard.mycard::v-deep {
	margin-top : 24px;
	border-color : @primary-color;
	border-style : dashed;
	.ant-card-body {
		padding : 8px 16px;
	}
	&.mycard-with-details ul.mycard-details > li .label {
		min-width : 90px;
	}
}
</style>
